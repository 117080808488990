.App {
  text-align: center;
}

.content {
  display: flex;
  text-align: left;
  flex-direction: column;
  min-height: 200px;
  width: 90%;
  max-width: 500px;
  margin-right: 5%;
  margin-left: 5%;
  background-color: #eff2f4;
  border-radius: 5px;
}

.img_title {
  padding: 5%;
  display: flex;
  flex-direction: row;
}

.button-section {
  padding-top: 50px;
}

.player {
  /* max-width: 20%; */
  width: 100%;
  /* margin-left: calc((100vw - 385px) / 2); */
  background-color: rgb(239 242 244 / .4);
  padding-top: 10px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 25px;
}

#lyrics a {
  text-decoration: none;
  color: black;
  cursor: text;
  pointer-events: none;
}

#title {
  color: #383838;
  text-transform: capitalize;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
}

#title_bis {
  color: #383838;
  text-transform: capitalize;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}

#artist_bis {
  max-height: 20px;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #666;
}

#artist {
  max-height: 20px;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #666;
}

.navbar {
  background-color: lightgray;
}

button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  color: #383838;
}

button:hover {
  color: #eb7d2b;
}